type StartBroadcastResponse = {
  id: null | string;
  key: string;
  rtmp: string;
  chat_url?: string;

  broadcast_id: null | string;
  channel_name: null | string;
  platform_id: null | string;
  region: null | string;
  chat_id: null | string;
};

export const useGoLiveStore = defineStore("go-live", () => {
  const api = useApiStore();
  const tracking = useTrackingStore();
  const dialogs = useDialogsStore();

  const currentBroadcast = ref<{
    startResponse: StartBroadcastResponse;
    platformType: MulitstreamChatSupportedPlatform;
  }>();

  const stopPreviousBroadcast = async () => {
    if (currentBroadcast.value) {
      stopBroadcast(
        currentBroadcast.value.platformType,
        currentBroadcast.value.startResponse?.broadcast_id || "all",
      );

      currentBroadcast.value = undefined;
    }
  };

  const startBroadcast = async (
    platformType: MulitstreamChatSupportedPlatform,
    payload: Record<string, any>,
  ) => {
    stopPreviousBroadcast();

    const response = await api.post<StartBroadcastResponse>(
      `/api/v5/obs-plugin/go-live/${platformType}/stream/start`,
      payload,
    );

    currentBroadcast.value = {
      startResponse: response.data,
      platformType,
    };

    return response;
  };

  const stopBroadcast = async (
    platformType: MulitstreamChatSupportedPlatform,
    broadcastId: string,
  ) => {
    await api.post(
      `/api/v5/obs-plugin/go-live/${platformType}/stream/${broadcastId}/end`,
    );
  };

  tracking.on("stopped_streaming", async () => {
    stopPreviousBroadcast();
  });

  const applyForTikTokStreaming = async () => {
    dialogs.showDialog("TIKTOK_APPLY");
  };

  return {
    startBroadcast,
    stopBroadcast,

    applyForTikTokStreaming,
  };
});
