<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 1C11 0.4 10.6 0 10 0H4C3.4 0 3 0.4 3 1V3H1C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5H2V12C2 13.1 2.9 14 4 14H10C11.1 14 12 13.1 12 12V5H13C13.5523 5 14 4.55228 14 4C14 3.44772 13.5523 3 13 3H11V1ZM5 2H9V3H5V2ZM5 6H6V11H5V6ZM8 6H9V11H8V6Z"
      fill="currentColor"
    />
  </svg>
</template>
