<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2.79 22.22 21.96">
    <path
      fill="#19d66b"
      fill-rule="evenodd"
      d="M17.2 2.793c.3 0 .5.2.5.5v.1l-.7 3.2c0 .1.1.3.2.3h6.5c.3 0 .592.2.5.6l-1.5 6.301c-.1.3-.3.4-.6.4l-3.5-.2c-1.1 0-2.3.3-3.2 1.3-1.2 1.2-1.3 3-.8 4.3.5 1.3 1.9 2 3.3 1.7 1.1-.2 1.7-1.3 1.6-2.2-.1-1-.5-1.3-1.1-1.3-.6 0-.8.3-.8.6s.1.5.3.7c.3.3-.3 1.2-1.1.7-.5-.3-.8-.9-.7-1.8.1-1.1 1-2.1 2.6-2.1s2.8 1 3.1 2.5c.5 1.9-.5 4.2-2.8 5.4-2.8 1.5-7.5 1.4-10.1-1.4-1.5-1.6-2-3.7-1.9-5.6 0-.6.1-1.3.3-1.9 0-.2-.1-.4-.3-.5H2.5c-.3 0-.5-.3-.5-.6v-.1l1.4-6.101c.1-.4.4-.6.8-.6h4.3c.2-.1.4-.2.5-.5l.7-3.1c.1-.4.5-.6.8-.6h6.7Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
