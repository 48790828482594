<template>
  <div class="flex h-16 items-center space-x-4">
    <div class="flex-shrink-0">
      <Icon size="1.5em" name="lucide:puzzle" />
    </div>
    <div class="flex-grow">
      <div>{{ destination.nickname }}</div>
    </div>
    <div class="flex flex-shrink-0 items-center space-x-4">
      <UiAlertDialog>
        <UiAlertDialogTrigger as-child>
          <Button variant="ghost" size="sm" condensed>
            <IconTrash class="h-5 w-5 opacity-70" />
          </Button>
        </UiAlertDialogTrigger>
        <UiAlertDialogContent>
          <UiAlertDialogHeader>
            <UiAlertDialogTitle>Are you absolutely sure?</UiAlertDialogTitle>
            <UiAlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              account and remove your data from our servers.
            </UiAlertDialogDescription>
          </UiAlertDialogHeader>
          <UiAlertDialogFooter>
            <UiAlertDialogCancel>Cancel</UiAlertDialogCancel>
            <UiAlertDialogAction @click="deleteTarget">
              Continue
            </UiAlertDialogAction>
          </UiAlertDialogFooter>
        </UiAlertDialogContent>
      </UiAlertDialog>

      <DialogEditDestination
        :stream-key="destination.streamKey"
        :nickname="destination.nickname"
        :url="destination.streamUrl"
        @save="onSave"
      >
        <UiDialogTrigger as-child>
          <Button variant="ghost" size="sm" condensed>
            <IconSettings class="h-5 w-5 opacity-70" />
          </Button>
        </UiDialogTrigger>
      </DialogEditDestination>

      <UiSwitch
        :checked="destination.enabled"
        size="lg"
        @update:checked="(checked) => onToggle(checked)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { updateRstUserTarget, deleteRstUserTarget } from "~/lib/streamlabs-api";

const api = useApiStore();
const multistream = useMultistreamStore();
const auth = useAuthStore();

const props = defineProps<{ destination: DestinationRelay }>();

const onSave = async ({
  nickname,
  url,
  streamKey,
}: {
  nickname: string;
  url: string;
  streamKey: string;
}) => {
  if (!api.multistream.user.targets.data) {
    return;
  }

  const index = api.multistream.user.targets.data.findIndex((item) => {
    return item.id === props.destination.relay_id;
  });

  if (index < 0) {
    return;
  }

  api.multistream.user.targets.data[index].label = nickname;
  api.multistream.user.targets.data[index].streamKey =
    url + (streamKey.length ? `/${streamKey}` : "");

  await updateRstUserTarget(api.multistream.user.targets.data[index]).catch(
    async (err) => {
      console.error(err);
      await api.multistream.user.targets.refresh();
    },
  );
};

const deleteTarget = async () => {
  if (!api.multistream.user.targets.data) {
    return;
  }

  const index = api.multistream.user.targets.data.findIndex((item) => {
    return item.id === props.destination.relay_id;
  });

  if (index < 0) {
    return;
  }

  const spliced = api.multistream.user.targets.data.splice(index, 1);

  await deleteRstUserTarget(spliced[0]).catch(async (err) => {
    console.error(err);
    await api.multistream.user.targets.refresh();
  });
};

const onToggle = async (checked: boolean) => {
  if (!api.multistream.user.targets.data) {
    return;
  }

  if (
    checked === true &&
    multistream.enabledDestinations.length >= 1 &&
    !auth.ensureUltra("multistream_toggle", {
      refl: "obsplugin-multistream",
    })
  ) {
    return;
  }

  const index = api.multistream.user.targets.data.findIndex((item) => {
    return item.id === props.destination.relay_id;
  });

  if (index < 0) {
    return;
  }

  api.multistream.user.targets.data[index].enabled = checked;

  await updateRstUserTarget(api.multistream.user.targets.data[index]).catch(
    async (err) => {
      console.error(err);
      await api.multistream.user.targets.refresh();
    },
  );
};
</script>
