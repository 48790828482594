<template>
  <DialogEditDestination @save="onSaveNewDestination">
    <slot>
      <UiDialogTrigger as-child>
        <Button variant="neutral">
          <IconAddCircle class="mr-2 h-4 w-4 opacity-70" />
          <span>Add a Destination</span>
        </Button>
      </UiDialogTrigger>
    </slot>
  </DialogEditDestination>
</template>

<script setup lang="ts">
import { createRstUserTarget } from "~/lib/streamlabs-api";

const api = useApiStore();

const onSaveNewDestination = async ({
  nickname,
  url,
  streamKey,
}: {
  nickname: string;
  url: string;
  streamKey: string;
}) => {
  if (api.multistream.user.targets.data) {
    api.multistream.user.targets.data.push({
      id: 999999,
      label: nickname,
      streamKey: url + (streamKey.length ? `/${streamKey}` : ""),
      enabled: true,
      userId: 0,
      user_id: 0,
      platform: "relay",
      dcProtection: false,
      idleTimeout: 999,
      mode: "landscape",
      createdAt: "",
      updatedAt: "",
      deletedAt: null,
    });
  }

  await createRstUserTarget({
    label: nickname,
    streamKey: url + (streamKey.length ? `/${streamKey}` : ""),
  }).catch((err) => {
    console.error(err);
  });

  await api.multistream.user.targets.refresh();
};
</script>
