<template>
  <ul class="divide-y divide-white/10">
    <template v-for="(item, i) in multistream.destinations" :key="i">
      <template v-if="item.type === 'platform'">
        <li :key="i">
          <DestinationListItemPlatform :destination="item" />
        </li>
      </template>

      <template v-else-if="item.type === 'relay'">
        <li :key="i">
          <DestinationListItemRelay :destination="item" />
        </li>
      </template>
    </template>
  </ul>
</template>

<script setup lang="ts">
const multistream = useMultistreamStore();
</script>
