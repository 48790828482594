<template>
  <div class="flex h-16 items-center space-x-4">
    <div class="flex-shrink-0">
      <Icon
        v-if="typeof config.icon === 'string'"
        size="1.5em"
        :name="config.icon"
        :class="config.iconClass"
      />
      <component
        :is="config.icon"
        v-else
        class="h-6 w-6"
        :class="config.iconClass"
      />
    </div>
    <div class="flex-grow">
      <div>{{ config.label }}</div>

      <div
        v-if="destination.platform_username"
        class="text-sm text-muted-foreground"
      >
        Connected to
        <strong>{{ destination.platform_username }}</strong>
      </div>
    </div>
    <div class="flex flex-shrink-0 items-center space-x-2">
      <template v-if="!destination.is_connected">
        <!--
        <Button
          :class="config.buttonClass"
          @click="multistream.connectPlatform(rstType)"
        >
          Connect
        </Button>
        -->

        <UiSwitch
          size="lg"
          @click="() => multistream.connectPlatform(rstType)"
        />
      </template>
      <template v-else-if="!destination.has_permission">
        <!--
        <Button :class="config.buttonClass" @click="doRepair">
          {{ repairLabel }}
        </Button>
        -->

        <UiSwitch size="lg" @click="doRepair" />
      </template>
      <template v-else>
        <UiSwitch
          :checked="destination.enabled"
          size="lg"
          :class="config.switchClass"
          @update:checked="
            (checked) => onDestinationToggle(destination, checked)
          "
        />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { updateRstUserProfile } from "~/lib/streamlabs-api";
import { PlatformTypeToRst } from "~/stores/multistream.store";

const multistream = useMultistreamStore();
const api = useApiStore();
const auth = useAuthStore();

const goLive = useGoLiveStore();

const props = defineProps<{ destination: DestinationPlatform }>();

const rstType = computed(() => {
  return PlatformTypeToRst[props.destination.platform_type];
});

const config = computed(() => {
  return multistream.platformConfigs[props.destination.platform_type];
});

const onDestinationToggle = async (
  destination: Destination,
  checked: boolean,
) => {
  if (!api.multistream.user.profile.data) {
    return;
  }

  if (
    checked === true &&
    multistream.enabledDestinations.length >= 1 &&
    // 2 destinations and one is TikTok = OK
    !(
      multistream.enabledDestinations.length === 1 &&
      (multistream.isTikTokEnabled || rstType.value === "tiktok_account")
    ) &&
    !auth.ensureUltra("multistream_toggle", {
      refl: "obsplugin-multistream",
    })
  ) {
    return;
  }

  api.multistream.user.profile.data[rstType.value] = checked;

  await updateRstUserProfile(api.multistream.user.profile.data);
};

const doRepair = async () => {
  if (
    props.destination.no_permission_reason === "platform_streaming" &&
    props.destination.platform_type === "tiktok"
  ) {
    await goLive.applyForTikTokStreaming();
    return;
  }

  await multistream.connectPlatform(rstType.value);
};
</script>
