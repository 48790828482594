<template>
  <UiAlertDialog @update:open="onUpdateOpen">
    <!-- <UiAlertDialogTrigger>Open</UiAlertDialogTrigger> -->

    <!-- https://www.radix-vue.com/utilities/slot ?? -->
    <!-- https://gist.github.com/loilo/73c55ed04917ecf5d682ec70a2a1b8e2#bonus-b-vue-3 -->
    <!-- <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template> -->
    <!-- <template #trigger> -->
    <slot />
    <!-- </template> -->

    <UiAlertDialogContent>
      <UiAlertDialogHeader>
        <UiAlertDialogTitle>{{ dialogTitle }}</UiAlertDialogTitle>
        <UiAlertDialogDescription>
          {{ dialogDescription }}
        </UiAlertDialogDescription>
      </UiAlertDialogHeader>

      <div class="grid gap-4 py-4">
        <div class="grid grid-cols-4 items-center gap-4">
          <label for="nickname" class="text-right">Nickname</label>
          <Input
            id="nickname"
            v-model="nickname"
            class="col-span-3"
            autocomplete="off"
            autofocus
          />
        </div>

        <div v-if="service" class="grid grid-cols-4 items-center gap-4">
          <label for="url" class="text-right">Service</label>
          <Input
            id="url"
            v-model="service"
            readonly
            class="col-span-3 opacity-60"
            autocomplete="off"
          />
        </div>

        <div class="grid grid-cols-4 items-center gap-4">
          <label for="url" class="text-right">Server URL</label>
          <Input id="url" v-model="url" class="col-span-3" autocomplete="off" />
        </div>

        <div class="grid grid-cols-4 items-center gap-4">
          <label for="stream-key" class="text-right">Stream Key</label>
          <Input
            id="stream-key"
            v-model="streamKey"
            class="col-span-3"
            autocomplete="off"
            type="password"
          />
        </div>
      </div>

      <UiAlertDialogFooter>
        <UiAlertDialogCancel>Cancel</UiAlertDialogCancel>
        <UiAlertDialogAction @click="onSave">Save</UiAlertDialogAction>
      </UiAlertDialogFooter>
    </UiAlertDialogContent>
  </UiAlertDialog>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    nickname?: string;
    url?: string;
    streamKey?: string;

    service?: string;
  }>(),
  {
    nickname: "",
    url: "",
    streamKey: "",
  },
);

const emit = defineEmits<{
  (
    e: "save",
    value: { nickname: string; url: string; streamKey: string },
  ): void;
  (e: "cancel"): void;
}>();

const isNew = ref(!(props.nickname || props.url || props.streamKey));

const nickname = ref(props.nickname);
const url = ref(props.url);
const streamKey = ref(props.streamKey);
const service = ref(props.service);

const dialogDescription = computed(() => {
  if (!isNew.value) {
    return `Make changes to your destination here. Click save when you're done.`;
  }

  return `Add the details of your RTMPS destination below. Click save when  you're done.`;
});

const dialogTitle = computed(() => {
  if (!isNew.value) {
    return `Edit Destination`;
  }

  return `New Destination`;
});

const onSave = () => {
  emit("save", {
    nickname: nickname.value,
    url: url.value,
    streamKey: streamKey.value,
  });
};

const onUpdateOpen = (open: boolean) => {
  if (open) {
    nickname.value = props.nickname;
    url.value = props.url;
    streamKey.value = props.streamKey;
    service.value = props.service;
  }
};
</script>
