<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
    <path
      fill="#F00044"
      d="M17.072 24.882c-3.806 0-6.881 3.075-6.825 6.844.038 2.419 1.369 4.519 3.328 5.69a6.624 6.624 0 0 1-1.078-3.534c-.056-3.769 3.019-6.844 6.825-6.844.75 0 1.472.122 2.147.338v-7.547a14.844 14.844 0 0 0-2.147-.16h-.103v5.55a7.05 7.05 0 0 0-2.147-.337ZM33.523 2.25h-2.006a11.528 11.528 0 0 0 4.36 6.947 11.612 11.612 0 0 1-2.354-6.947Z"
    />
    <path
      fill="#F00044"
      d="M45 13.735c-.74 0-1.453-.075-2.156-.206v5.41c-2.55 0-5.025-.498-7.35-1.491a19.205 19.205 0 0 1-4.182-2.438l.038 16.678c-.019 3.75-1.5 7.266-4.181 9.919a14.22 14.22 0 0 1-7.95 3.975 14.83 14.83 0 0 1-2.147.16c-3.207 0-6.263-1.041-8.747-2.963.281.337.581.665.91.984a14.287 14.287 0 0 0 10.096 4.135c.722 0 1.444-.057 2.147-.16 3-.44 5.765-1.819 7.95-3.975 2.681-2.653 4.162-6.169 4.181-9.919l-.14-16.678a18.414 18.414 0 0 0 4.18 2.438 18.83 18.83 0 0 0 7.35 1.49"
    />
    <path
      fill="#08FFF9"
      d="M9.206 23.823c2.672-2.654 6.225-4.125 10.013-4.154v-1.996a14.83 14.83 0 0 0-2.147-.16c-3.825 0-7.416 1.472-10.116 4.153-2.653 2.635-4.172 6.235-4.162 9.975 0 3.77 1.49 7.303 4.18 9.975.432.422.873.816 1.341 1.172a13.964 13.964 0 0 1-3.28-8.99c.009-3.741 1.518-7.341 4.171-9.975ZM42.844 13.529v-2.006h-.02c-2.605 0-5.005-.863-6.937-2.325a11.452 11.452 0 0 0 6.957 4.33Z"
    />
    <path
      fill="#08FFF9"
      d="M18.937 40.519a7.044 7.044 0 0 0 2.531-.328c2.72-.891 4.679-3.422 4.679-6.404l.009-11.156V2.25h5.363a11.683 11.683 0 0 1-.225-2.156h-7.388v20.372l-.01 11.156c0 2.981-1.959 5.512-4.678 6.403a6.652 6.652 0 0 1-2.53.328 6.877 6.877 0 0 1-3.113-.947 6.84 6.84 0 0 0 5.362 3.113Z"
    />
    <path
      fill="#fff"
      d="M19.219 45.581c3-.44 5.765-1.819 7.95-3.975 2.68-2.653 4.162-6.169 4.18-9.919l-.037-16.678a18.415 18.415 0 0 0 4.182 2.438 18.83 18.83 0 0 0 7.35 1.49v-5.409a11.453 11.453 0 0 1-6.957-4.331 11.485 11.485 0 0 1-4.359-6.947h-5.372v20.372l-.01 11.156c0 2.981-1.959 5.513-4.677 6.403a6.654 6.654 0 0 1-2.532.328 6.84 6.84 0 0 1-5.362-3.103c-1.96-1.162-3.29-3.272-3.328-5.69-.056-3.77 3.018-6.844 6.825-6.844.75 0 1.472.122 2.147.337v-5.55c-3.788.029-7.341 1.5-10.013 4.154-2.653 2.634-4.172 6.234-4.162 9.965 0 3.319 1.153 6.469 3.28 8.99a14.26 14.26 0 0 0 8.748 2.963 13.62 13.62 0 0 0 2.147-.15Z"
    />
  </svg>
</template>
